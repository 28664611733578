<template>
  <el-upload
    class="image-upload"
    :class="{ circle: circle, form: isForm }"
    v-loading="isImportLoading"
    drag
    action="#"
    :accept="ALL_FILE_TYPE.IMAGE.join(',')"
    :limit="1"
    :auto-upload="false"
    :file-list="photoUrlList"
    :on-change="handleUpload"
    :show-file-list="false"
  >
    <div class="dashed-photo-container">
      <el-image
        v-if="displayPhotoUrl"
        :src="displayPhotoUrl"
        :class="{ 'full-size': isForm && !isEmptyPhoto }"
        :preview-src-list="canChangePic ? [displayPhotoUrl] : null"
        fit="contain"
        alt="profile picture"
      />
    </div>
    <div slot="trigger">
      <template v-if="canChangePic">
        <el-row class="change-delete-text-wrapper" type="flex" justify="end" align="middle">
          <div>
            <span class="text-primary text-underline">{{ $t("CHANGE_IMG") }}</span>

            <br v-if="changeDeleteVertically"/>
            <div class="change-delete-spacing" v-else>&nbsp;</div>
            
            <span class="text-grey" @click.stop="deleteImg()">{{ $t("DELETE_IMG") }}</span>
          </div>
        </el-row>
      </template>
      <template v-else-if="disabled == false">
        <el-row class="upload-text" type="flex" justify="end" align="middle">
          <i v-if="!isForm || !isMobile" class="custom-icon icon-camera mini bg-white"/>
          <span class="text-grey">{{ $t("UPLOAD_IMG") }}</span>
        </el-row>
      </template>
    </div>
  </el-upload>
</template>

<script>
import { mapState } from "vuex";
import { upload, fileUpload } from "@/api/utility"
import { generalMixin } from "@/utils/general-mixin.js"
import { ALL_FILE_TYPE } from "@/constants"

const emptyPhotoUrl = require('../assets/pngs/profile-pic-placeholder.png')
const emptyPhotoUrlForm = require('../assets/pngs/no-image-placeholder.png')

export default {
  name: "ImageUpload",
  props: {
    photoFileUpload: { type: Object },
    onDeleteUpload: { type: Function },
    circle: { type: Boolean },
    isForm: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    changeDeleteVertically: { type: Boolean, default: true },
    photoUploadList: { type: Array, default: null }, // optional for multi-language
    language: { type: Object, default: null }, // optional for multi-language
    replicateToAllLanguage: { type: Boolean, default: false }, // optional for multi-language
  },
  emits: ['update:photoFileUpload', 'update:photoUploadList'],
  mixins: [ generalMixin ],
  data() {
    return {
      isImportLoading: false,
      ALL_FILE_TYPE,
      photoUrlList: [],
    }
  },
  computed: {
    canChangePic() {
      return this.disabled == false
              && this.displayPhotoUrl != null 
              && this.displayPhotoUrl != emptyPhotoUrl 
              && this.displayPhotoUrl != emptyPhotoUrlForm
    },
    isEmptyPhoto() {
      return this.displayPhotoUrl === emptyPhotoUrlForm || this.displayPhotoUrl === emptyPhotoUrl
    },
    displayPhotoUrl() {
      const emptyUrl = this.isForm ? emptyPhotoUrlForm : emptyPhotoUrl
      const singlePhoto = this.photoFileUpload?.upload
      if (singlePhoto?.url != null) { 
        return singlePhoto.url
      } else if (this.photoUploadList != null && this.photoUploadList.length != 0) {
        const found = this.photoUploadList.find(el => el.language.id == this.language.id)?.upload?.url
        return found || emptyUrl
      } else {
        return emptyUrl
      }
    },
    ...mapState('app', ['langList']),
  },
  watch: { },
  methods: {
    handleUpload(file) {
      const photoRaw = file.raw;
      this.isImportLoading = true;

      if (photoRaw) {
        const formData = new FormData();
        formData.append("file", photoRaw);

        upload(formData).then(async response => {
            const result = response?.result
            if (!result?.haveError) {
              this.photoUrlList = [];
              const fileUploadObj = { upload: result }
              if (this.photoUploadList) {
                if (this.replicateToAllLanguage) {
                  const list = []
                  await this.langList.forEach(lang => {
                    this.handleFileUpload(fileUploadObj, lang).then(obj => {
                      list.push(obj)
                    })
                  })
                  this.$emit("update:photoUploadList", list)
                } else {
                  this.handleFileUpload(fileUploadObj)
                }

              } else { // not multi-language ends here
                this.$emit("update:photoFileUpload", fileUploadObj)
              }
            }
          })
          .finally(() => (this.isImportLoading = false));
      }
    },
    handleFileUpload(obj, languageObj = null) {
      const payload = {
        fileUpload: obj,
        language: languageObj || this.language
      }
      return fileUpload(payload).then(response => {
        const result = response?.result
        if (!result?.haveError) {
          const fileUploadObj = result
          if (languageObj == null) {
            const list = this.photoUploadList
                          .filter(el => el.language.id != this.language.id) // remove first
            list.push(fileUploadObj) // then add back
            this.$emit("update:photoUploadList", list)
          }
          return fileUploadObj
        }
      })
    },
    deleteImg() {
      this.$confirm(this.$t("CONFIRM_DELETE_IMG"), this.$t("ARE_YOU_SURE"))
      .then(() => {
        this.$emit("update:photoFileUpload", null)
        if (this.photoUploadList) {
          const list = this.photoUploadList
                          .filter(el => el.language.id != this.language.id)
          this.$emit("update:photoUploadList", list)
        }
      })
      .catch(() => {});
    }
  },
  mounted() {
  }
}
</script>
<style lang="scss">
@import "@/assets/style/_variables.scss";

.image-upload {
  overflow: hidden;

  .el-upload-dragger {
    background: transparent;
    height: 109px;
    border: 0;
    display: flex;
    justify-content: flex-start;
  }
  .dashed-photo-container {
    border-radius: 13px;
    border: 3px dashed $primary;
    position: absolute;
    top: 10px;
  }
  .change-delete-text-wrapper,
  .upload-text {
    position: absolute;
    height: 128px;
    left: 94px;
    top: 0;
    z-index: 1;
    word-wrap: break-word;
    span { margin-left: 5px }
  }
  &.circle {
    .dashed-photo-container,
    img { 
      border-radius: 50%;
    }
    .dashed-photo-container {
      width: 109px;
      height: 109px;
    }
    img {
      width: 99px;
      height: 99px;
      margin-left: 2px;
      margin-top: 2px;
    }
  }
  &.form {
    .dashed-photo-container {
      width: 80%;
      height: 109px;
      padding: 5px;
    }
    .el-image {
      background: #F3F5FA 0% 0% no-repeat padding-box;
      border-radius: 7px;
      width: 100%;
      height: 100%;
      text-align: center;
    }
    .change-delete-text-wrapper,
    .upload-text {
      width: 94.5%
    }
    img {
      width: 55px;
    }
    .full-size img{
      width: 100%;
      object-fit: cover!important;
    }

    .el-upload,.el-upload-dragger {
      width: 100%;
    }
    .change-delete-text-wrapper,
    .upload-text {
      right: 37px;
      span { margin-left: 5px }
    }
  }

  .change-delete-spacing {
    display: inline-block;
    width: 100px;
  }
  .icon-camera {
    border: 1px solid #BCC1DA;
    border-radius: 8px;
    height: 25px;
    width: 25px;
  }
}

.mobile .image-upload {
  &.form {
    .change-delete-text-wrapper,
    .upload-text {
      right: 0px;
      width: 100%;
    }
    .dashed-photo-container {
      width: calc(100% - 90px);
    }
  }
}
</style>