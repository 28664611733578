import { generalMixin } from "@/utils/general-mixin";
import { mapGetters } from "vuex";
// import cloneDeep from 'lodash/cloneDeep'

/*
  This is used by (please amend accordingly)
  1. Article management

  DO NOT CHANGE ANYTHING HERE,
  you can only override anything in your own component


  NOTE :
  -ONLY member lv3/lv4 & BO user can add/edit/archive article
  -Admin got full access in article module (view/edit/archive ALL articles)
  -Manager can view/edit all articles but can only archive OWN article
  -Officer can view all but can only edit/archive OWN article

  **** member lv3/lv4 can only view OWN article

*/

export const articleMixin = {
  mixins: [generalMixin],
  watch: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    checkAccessRight(action) {
      switch (action) {
        case "add":
          return this.isBOUser || this.isMemberThree || this.isMemberFour;
        case "edit":
          return this.fullAccess || this.isMemberThree || this.isMemberFour;
        case "archive":
          return this.isAdmin || this.isMemberThree || this.isMemberFour;
        default:
          return false;
      }
    },
    isOwner(userId) {
      return userId == this.user.id;
    },
  },
  mounted() {},
};
