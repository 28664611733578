<!-- 
  This footer is only for pages that need fixed footer for actions

  HOW TO USE
  1. Put this component to the very bottom of your component. (right inside the outermost wrapper)
  2. To activate each button, you need to pass in the function to the props handleXXXX.
  3. Props with null will hide the button, u can use this to hide for different scenarios.
  4. Pass in backToText/submitBtnText, to have different text.
  5. If your submit is done by using @submit.native.prevent="handleSubmit" on el-form,
      please pass in empty function. -> e.g. :handleSubmit=" isEditing || isAdding ? () => {} : null"
      Also, put the component INSIDE el-form
  6. isLoading is to prevent user click again when performing action / loading
  7. Need add a class .page-footer in .app-container element (class="app-container page-footer")

  E.G.
  <page-footer
    :isLoading="isLoading"
    :handleBackTo="exit"
    :handleExport="handleExport"
    :handleClear="isEditing || isAdding ? handleClear : null"
    :handleArchive="handleArchive"
    :handleSubmit=" isEditing || isAdding ? () => {} : null"
    :submitBtnText="isEditing ? 'Save' : 'Add'"
    backToText="Back to Meter List"
    :handleAction="() => {}"
    :actionBtnText="BUTTON"
  />

 -->
<template>
  <div id="page-footer">
    <div>
      <el-button 
        v-loading="isLoading"
        v-if="handleBackTo"
        @click="handleBackTo" 
        :disabled="false"
        class="button text-grey link-style with-icon back-button"
      >
        <i class="custom-icon icon-arrow-left mini"/>
        <template v-if="!isMobile">{{ backToText }}</template>
      </el-button>

      <el-row 
        v-loading="isLoading" 
        type="flex" 
        justify="center"
        :class="{ 'with-back': handleBackTo }"
      >
        <!-- activate me back if needed -->
        <!-- <el-button v-if="handleExport" @click="handleExport" 
          class="button white">{{ isMobile ? 'Export' : 'Export Details' }}</el-button> -->

        <el-button 
          v-if="handleClear" 
          @click="handleClear"
          :class="customClearClass"
        >
          {{ clearBtnText }}
        </el-button>

        <el-button 
          v-if="handleArchive" 
          @click="beforeHandleArchive"
          class="button red link-style"
        >
          {{ archiveBtnText }}
        </el-button>

        <el-button 
          v-if="handleSubmit" 
          @click="handleSubmit"
          class="button primary" 
          native-type="submit"
        >
          {{ submitBtnText }}
        </el-button>

        <el-button 
          v-if="handleAction" 
          @click="handleAction"
          :class="customActionClass"
        >
          {{ actionBtnText }}
        </el-button>

        <el-button 
          v-if="handleComplain && isMobile" 
          @click="handleComplain"
          class="button text-grey link-style with-icon complain-button" 
          native-type="submit"
        >
          <i class="custom-icon icon-complain-article mini"/>
          {{ $t("MAKE_A_COMPLAINT") }}
        </el-button>

      </el-row>

      <el-button 
        v-if="handleComplain && !isMobile" 
        @click="handleComplain"
        class="button text-grey link-style with-icon complain-button floating-right" 
        native-type="submit"
      >
        <i class="custom-icon icon-complain-article mini"/>
        {{ $t("MAKE_A_COMPLAINT") }}
      </el-button>

    </div>
  </div>
</template>

<script>
import { generalMixin } from "@/utils/general-mixin";

export default {
  name: "PageFooter",
  mixins: [generalMixin],
  props: {
    isLoading: { type: Boolean, default() { return false } },
    backToText: { type: String, default() { return this.$t("BACK") } },
    archiveConfirmText: { type: String, default() { return this.$t("ARCHIVE_CONFIRMATION") } },
    clearBtnText: { type: String, default() { return this.$t("CLEAR") } }, 
    submitBtnText: { type: String, default() { return this.$t("SUBMIT") } }, 
    actionBtnText: { type: String, default() { return this.$t("SUBMIT") } }, 
    archiveBtnText: { type: String, default() { return this.$t("ARCHIVE") } }, 

    // Pass the function to this to activate the button.
    handleBackTo: { type: Function },
    // handleExport: { type: Function }, // activate me back if needed
    handleClear: { type: Function },
    customClearClass: { type:String, default() { return 'button black reversed-color text-grey' } },
    handleArchive: { type: Function },
    handleSubmit: { type: Function },
    handleAction: { type: Function },
    handleComplain: { type: Function },
    customActionClass: { type:String, default() { return 'button primary reversed-state' } }
  },
  methods: {
    beforeHandleArchive() {
      this.$confirm(this.archiveConfirmText, this.$t("ARE_YOU_SURE"), {
        confirmButtonText: this.$t("ARCHIVE"),
        cancelButtonText: this.$t("CANCEL"),
        type: 'warning'
      })
      .then(this.handleArchive)
      .catch(() => {});
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/style/_variables.scss";

#page-footer {
  position: fixed;
  bottom: 0;
  left: -$spacingLeftRight;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: $spacingLeftRight;
  width: calc(100% + $spacingLeftRight);
  z-index: 102;
  background-color: #F3F5FA;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);

  >div {
    max-width: $viewMaxWidth;
    margin: auto;
    height: 35px;
  }
  .el-button {
    min-width: 10rem;
    margin: auto 1rem;
    &.back-button {
      background-color: transparent;
      position: absolute;
      left: 0;
      @media (min-width: $viewMaxWidth) {
        left: calc((100vw - $viewMaxWidth) / 2);
      }
      bottom: 1rem;
      z-index: 102;
      &:hover {
        background-color: transparent;
      }
    }
    &.complain-button {
      background-color: transparent;
      z-index: 102;
      font-weight: bold;
      &:hover {
        background-color: transparent;
      }
      &.floating-right {
        position: absolute;
        right: 0;
        @media (min-width: $viewMaxWidth) {
          right: calc((100vw - $viewMaxWidth) / 2);
        }
        bottom: 1rem;
      }
    }
  }
}

.mobile {
  #page-footer {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    .el-button {
      &.back-button {
        left: -40px;
        bottom: 8px;
      }
      &.complain-button {
        right: -20px;
        bottom: 8px;
      }
    }
    .el-row {
      margin: auto;
      &.with-back {
        margin-left: 4rem;
      }
      .el-button {
        min-width: 0;
        width: 100%;
      }
    }
  }
}
</style>