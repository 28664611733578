import request from "@/utils/request";

export async function getArticleList(postData) {
  return await request({
    url: "/article/list",
    method: "post",
    data: postData,
  });
}

// Public use
export async function getArticleById(id) {
  return await request({
    url: "/article/" + id,
    method: "get",
  });
}
export async function getArticleByMetaTitle(meta) {
  return await request({
    url: "/article/meta-title/" + meta,
    method: "get",
  });
}
//  Read article attendance
export async function readArticle() {
  return await request({
    url: "/attendance-record/read-article",
    method: "get",
  });
}

// Share article
export async function shareArticle(postData) {
  return await request({
    url: "/share-log/create",
    method: "post",
    data: postData,
  });
}

// BO use
export async function getBOArticleById(postData) {
  return await request({
    url: "/article/id",
    method: "post",
    data: postData,
  });
}

export async function addArticle(postData) {
  return await request({
    url: "/article/create",
    method: "post",
    data: postData,
  });
}

export async function updateArticle(postData) {
  return await request({
    url: "/article/update",
    method: "post",
    data: postData,
  });
}

export async function archiveArticle(id) {
  return await request({
    url: "/article/all/"+id,
    method: "delete",
  });
}

// all interactions

export async function createArticleInteraction(postData) {
  return await request({
    url: "/article-interaction/create/",
    method: "post",
    data: postData
  });
}

export async function getAllArticleInteraction(postData) {
  return await request({
    url: "/article-interaction/list/",
    method: "post",
    data: postData
  });
}
